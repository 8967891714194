/* jshint esversion: 6, sourceType: module, browser: true, devel: true, indent: 2, curly: true, eqeqeq: true, futurehostile: true, latedef: true, undef: true, unused: true */
/* global jsSrc, jQuery */

import lottie from 'lottie-web';
import scrollMonitor from 'scrollmonitor';

export class Animation {
	constructor() {
  	this.instances = []; // array to keep references to the lottie instances
  	this.scrollMonitors = []; // array to keep references to the scroll monitor instances
		this.jsonPath = jsSrc['templateDirectory'] + '/library/animations/'; // path to animation files
	}

	init() {
    const _this = this;

    jQuery('.lottie-animation').each(function(index, item) { // loop over each animation DOM element
      const element = jQuery(item)[0];
  
      _this.instances[index] = lottie.loadAnimation({ // create lottie animation where DOM element is
        container: element,
        renderer: 'svg',
        loop: false,
        autoplay: false,
        path: _this.jsonPath + jQuery(item).data('id') + '.json'
      });
      
      _this.scrollMonitors[index] = scrollMonitor.create(element, { // create scroll monitor instances for DOM element triggered 1/3 of the way onto the screen. On trigger play the lottie animation
        top: -(scrollMonitor.viewportHeight / 3)
      }).enterViewport(function() {
        _this.instances[index].play()
      });
      
    });
	}
}