/* jshint esversion: 6, browser: true, devel: true, indent: 2, curly: true, eqeqeq: true, futurehostile: true, latedef: true, undef: true, unused: true */

import { Animation } from './modules/animation.js';

class Site {
	constructor() {
		this.animation = new Animation();

		this.animation.init();
	}
}

new Site();